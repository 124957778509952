import React, { useState } from 'react';
import { FaTicketAlt, FaCoins } from 'react-icons/fa'; 
import './CouponCard.css'; 
import moment from 'moment';

const AdminCouponCard = ({ coupon, onUpdate }) => {
  const [isValid, setIsValid] = useState(coupon.isValid);

  // Handle switch toggle
  const handleToggle = () => {
    const updatedStatus = !isValid;
    setIsValid(updatedStatus);
    onUpdate(coupon._id, updatedStatus); 
  };

  return (
    <div className={`coupon-card border rounded p-3 d-flex justify-content-between align-items-center ${isValid ? 'enabled' : 'disabled'}`}>
      <div className="d-flex align-items-center">
        <FaTicketAlt className="coupon-icon me-3" /> {/* Left ticket icon */}
        <div>
          <h5 className="m-0">
            <span className="text-danger">Use Promocode </span>
            <span className="coupon-code">{coupon.couponCode}</span>
          </h5>
          <p className="text-muted small">
            <FaCoins className="me-1" /> {/* Coin Icon */}
            {coupon.couponAmount}
          </p>
          <p className="text-muted small">
            <strong>Valid From:</strong> {moment(coupon.startDate).format('MMM DD, YYYY')}
          </p>
          <p className="text-muted small">
            <strong>Valid Until:</strong> {moment(coupon.endDate).format('MMM DD, YYYY')}
          </p>
          <p className="text-muted small">{coupon.isPersonal ? 'Personalized' : 'General'}</p>
        </div>
      </div>

      <div className="admin-controls d-flex align-items-center">
        <label className={`form-check-label me-2 ${isValid ? 'text-success' : 'text-danger'}`} htmlFor={`switch-${coupon.code}`}>
          {isValid ? 'Enabled' : 'Disabled'}
        </label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id={`switch-${coupon.code}`}
            checked={isValid}
            onChange={handleToggle}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminCouponCard;
