import React, { useEffect, useState, useTransition } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import { Base_Url } from "../../../misc";

const CreateCoupon = () => {
  const [couponList, setCouponList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [foodData, setFoodData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPending, startTransition] = useTransition();
  const PAGE_SIZE = 3;

  const [formData, setFormData] = useState({
    couponCode: "",
    couponAmount: "",
    couponStartDate: "",
    couponEndDate: "",
    isForAllProduct: true,
    couponForProduct: [],
    isForAllUser: true,
    couponForUser: [],
    couponIsPercentage: false,
    couponMinPurchase: "",
    usageLimit: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value === "true",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalData = { ...formData };

    startTransition(() => {
      axios
        .post(`${Base_Url}/create-coupon`, finalData)
        .then(() => {
          Swal.fire("Success", "Coupon created successfully", "success");
          fetchCoupons();
          resetForm();
        })
        .catch((err) => console.error(err));
    });
  };

  const fetchCoupons = () => {
    axios
      .get(`${Base_Url}/get-coupon`)
      .then((res) => {
        startTransition(() => {
          setCouponList(res.data.data);
        });
      })
      .catch((err) => console.error(err));
  };

  const resetForm = () => {
    setFormData({
      couponCode: "",
      couponAmount: "",
      couponStartDate: "",
      couponEndDate: "",
      isForAllProduct: true,
      couponForProduct: [],
      isForAllUser: true,
      couponForUser: [],
      couponIsPercentage: false,
      couponMinPurchase: "",
      usageLimit: "",
    });
  };

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("Token");
      const headers = token ? { token } : {};
      const res = await axios.get(`${Base_Url}/get-users`, { headers });

      if (res.status === 200 && res.data.status === "Success") {
        setUserData(
          res.data.data.map((user) => ({ value: user._id, label: user.userFullName }))
        );
      }
    } catch (error) {
      toast.error("Failed to fetch users");
    }
  };

  const fetchFoods = async () => {
    try {
      const token = localStorage.getItem("Token");
      const headers = token ? { token } : {};
      const res = await axios.get(`${Base_Url}/get-food`, { headers });

      if (res.status === 200 && res.data.status === "Success") {
        setFoodData(
          res.data.data.map((food) => ({ value: food._id, label: food.foodName }))
        );
      }
    } catch (error) {
      toast.error("Failed to fetch food items");
    }
  };

  useEffect(() => {
    fetchCoupons();
    fetchUsers();
    fetchFoods();
  }, []);

  return (
    <>
      <PageTitle activeMenu="Coupon" motherMenu="Coupon" />
      <div className="border rounded p-4">
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label>Coupon Code</label>
            <input
              type="text"
              className="form-control"
              name="couponCode"
              value={formData.couponCode}
              onChange={handleInputChange}
              placeholder="Enter Coupon Code"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label>Coupon Amount</label>
            <input
              type="number"
              className="form-control"
              name="couponAmount"
              value={formData.couponAmount}
              onChange={handleInputChange}
              placeholder="Enter Coupon Amount"
              required
            />
          </div>
          <div className="form-group mb-3">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              name="couponStartDate"
              value={formData.couponStartDate}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label>End Date</label>
            <input
              type="date"
              className="form-control"
              name="couponEndDate"
              value={formData.couponEndDate}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Product Selection */}
          <div className="form-group mb-3">
            <label>Coupon for Products</label>
            <div>
              <input
                type="radio"
                name="isForAllProduct"
                value="true"
                checked={formData.isForAllProduct}
                onChange={handleRadioChange}
              />
              <label className="ms-2">All Products</label>
            </div>
            <div>
              <input
                type="radio"
                name="isForAllProduct"
                value="false"
                checked={!formData.isForAllProduct}
                onChange={handleRadioChange}
              />
              <label className="ms-2">Specific Product</label>
            </div>
            {!formData.isForAllProduct && (
              <Select
                isMulti
                options={foodData}
                onChange={(selected) =>
                  setFormData((prev) => ({
                    ...prev,
                    couponForProduct: selected.map((item) => item.value),
                  }))
                }
              />
            )}
          </div>

          {/* User Selection */}
          <div className="form-group mb-3">
            <label>Coupon for Users</label>
            <div>
              <input
                type="radio"
                name="isForAllUser"
                value="true"
                checked={formData.isForAllUser}
                onChange={handleRadioChange}
              />
              <label className="ms-2">All Users</label>
            </div>
            <div>
              <input
                type="radio"
                name="isForAllUser"
                value="false"
                checked={!formData.isForAllUser}
                onChange={handleRadioChange}
              />
              <label className="ms-2">Specific User</label>
            </div>
            {!formData.isForAllUser && (
              <Select
                isMulti
                options={userData}
                onChange={(selected) =>
                  setFormData((prev) => ({
                    ...prev,
                    couponForUser: selected.map((item) => item.value),
                  }))
                }
              />
            )}
          </div>

          <div className="form-group mb-3">
            <label>Is Percentage</label>
            <input
              type="checkbox"
              name="couponIsPercentage"
              checked={formData.couponIsPercentage}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group mb-3">
            <label>Minimum Purchase Amount</label>
            <input
              type="number"
              className="form-control"
              name="couponMinPurchase"
              value={formData.couponMinPurchase}
              onChange={handleInputChange}
              placeholder="Enter Minimum Purchase Amount"
              required
            />
          </div>

          <div className="form-group mb-3">
            <label>Usage Limit</label>
            <input
              type="number"
              className="form-control"
              name="usageLimit"
              value={formData.usageLimit}
              onChange={handleInputChange}
              placeholder="Enter Usage Limit"
              required
            />
          </div>

          <Button type="submit" className="btn btn-primary">
            Create Coupon
          </Button>
        </form>
      </div>
    </>
  );
};

export default CreateCoupon;
