import React, { useEffect, useState } from 'react';
import AdminCouponCard from './Cart/CouponCart';
import axios from 'axios';
import { BaseURL } from '../../../Helper/config'; // Assuming this is your base URL
import { toast } from 'react-toastify';

const AdminCouponManagement = () => {
  // State to store coupons
  const [coupons, setCoupons] = useState([]);

  // Function to fetch coupons from the API
  const getCoupon = async () => {
    try {
      const response = await axios.get(`${BaseURL}/get-coupon`);
      
      console.log(response)
      setCoupons(response?.data?.data); // Assuming the response contains the list of coupons
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };

  // Fetch coupons when the component mounts
  useEffect(() => {
    getCoupon();
  }, []);

  // Function to update the coupon status (enable/disable)
  const updateCouponStatus = async (id, isValid) => {
    try {
      const response = await axios.post(`${BaseURL}/update-coupon/${id}`, {
      
        isValid,
      });
      console.log('Coupon updated:', response.data);
      toast.success(`Coupon ${isValid ? 'enabled' : 'disabled'} successfully!`);
      getCoupon();
    } catch (error) {
     toast.error('Error updating coupon. Please try again.');
      console.error('Error updating coupon:', error);
    }
  };

  return (
    <div className="container mt-5">
      {coupons.length>0 && coupons.map(coupon => (
        <AdminCouponCard key={coupon.code} coupon={coupon} onUpdate={updateCouponStatus} />
      ))}
    </div>
  );
};

export default AdminCouponManagement;
